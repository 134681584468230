.formRow {
  text-align:center;
    input{
      width: 100%;
      font-size: 1.5rem;
      line-height: 1;
      font-weight: 400;
      text-align: left;
      padding: 10px 5px;
      margin: 10px auto;
      border: 1px solid #9e9e9e;
      outline: none;
      float: left;
      align-content:flex-start;
      
    }

    label{
      text-align: center;
    }
  }