.infoContainer{

    padding: 0 10% 3rem;

    .infoDesc{
        .subHeading{
            margin: 0 auto 0;
        }
    }


    h2{
        margin: 5rem auto 5rem;
    }

}


@media only screen and (max-width: 980px) {
    .infoContainer{
        padding: 0 2%;
    }
}