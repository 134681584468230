.authWrapper {
    display: block;
    width: 100%;
    max-width: 40.0rem;
    margin: 4rem auto 6rem;
    border: 1px solid black;
  
    .wrap {
      padding: 10px;
    }
  }