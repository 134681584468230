.infoContainer{
    .reviewContainer{

        display: flex;
        flex-wrap: wrap;
        margin-bottom: 5rem;

        .reviewContent{
            display: flex;
            flex: 25%;
            max-width: calc(25% - 3rem);
            padding: 0 4px;
            flex-direction: column;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            padding: 1rem;
            margin: 1.5rem;
            img{
                padding: 2rem;
            }

            h4{
                font-size: 1.4rem;
                line-height: 2rem;
            }

            h5{
                margin: 0.5rem;
                color: rgb(46, 46, 46);

            }
        }
    }
}

@media only screen and (max-width: 980px) {
    .infoContainer{
        .reviewContainer {
            .reviewContent{
                flex: 50%;
                max-width: calc(50% - 3rem);
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .infoContainer{
        .reviewContainer {
            .reviewContent{
                flex: 100%;
                max-width: 100%;
            }
        }
    }
}
