.SlidesContainer{

  margin-top: 4rem;
  margin-bottom: 4rem;
  

  .slider-wrapper {
    width: 100%;
    margin: auto;
    
  }
  .slider-wrapper2 {
    margin-top: 0;
  }
  .slick-slide {
    text-align: center;
    position: relative;
  }
  .slick-slide:focus {
    outline: none;
  }
  .slick-slide-title {
    text-transform: capitalize;
  }
  .slick-slide-image {
    max-width: 95%; //65
    height: auto;
    border-radius: 8px;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  }
  .slick-slide-label {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0px;
    font-size: 1.5em;
    bottom: 0px;
    width: 100%;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #777777;
    content: "" !important;
  }
  .slick-prev {
    left: -52px !important;
  }
  
  .slick-track {
    height:auto;
  }
  .next-slick-arrow,
  .prev-slick-arrow {
  color: #000000;
  font-size: 48px;
  }
  
  
  .thumbnail-slider-wrap {
    width: 15%;
    height: 0px;
    padding: 0 10px;
  }
  .thumbnail-slider-wrap .slick-track .slick-slide {
    text-align: center;
    padding-bottom: 10px;
  }
  .thumbnail-slider-wrap .slick-track .slick-slide img {
    width: 100%;
  }

  .App2 {
    height: auto;
  }

    .slick-list{
    border: black;
    -moz-box-sizing: border-box;
         box-sizing: border-box;
    outline:black;
  }
}



@media only screen and (max-width: 980px) {
  .SlidesContainer{
    .slider-wrapper {
      width: 100%;
      margin-left: 0;
    }
  }
  .slider-wrapper2 {
    padding-top: 4rem;
    margin-left: 0;
  }
}