.itemCards {
  img {
    display: block;
    width: 100%;
    margin: 0;
  }

  .removeButton{
  
    display: block;
    width: 100%;
    font-size: 1.8rem;
    line-height: 1;
    font-weight: 300;
    text-transform: uppercase;
    color: white;
    background: rgba(211, 141, 113, 0.61);
    padding: 2rem 0;
    margin: 3rem auto;
    border: 0;
    outline: none;
    cursor: pointer;
    text-align: center;
  }

}
