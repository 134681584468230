.SizeChartContainer{

    padding-top: 5rem;
    padding-bottom: 5rem;

    h2{
        text-align: left;
    }
    
    .sizeGuideContainer{
        margin: 0;

    
        .SizeGuideTCell {
            font-size: 1.4rem;
            font-weight: 100;
        }
    
        .SizeGuideTHead {
            font-size: 1.5rem;
            font-weight: 400;
        }

        .shoestitle{
            padding-top: 5rem;
        }
    }

    img{
        margin: 0;
    }
}

@media only screen and (max-width: 980px) {
  
    .SizeChartContainer {
        .sizeGuideContainer{
            .Note{
                margin-bottom: 5rem;
            }

            .Note > * {
                margin-top: 1.5rem;
            }
        }
    }
  }



