
.slider-wrapper {
  width: 75%;
  margin: auto;
  // margin-left: 160px;
  margin-left: 20%;
  margin-top: 0px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.slick-slide {
  text-align: center;
  position: relative;
  padding-left: 0.1%;
}
.slick-slide:focus {
  outline: none;
}
.slick-slide-title {
  text-transform: capitalize;
}
.slick-slide-image {
  max-width: 96%; //65
  height: auto;
  border-radius: 8px;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
    0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  margin: 0;
}
.slick-slide-label {
  color: #fff;
  padding: 10px;
  position: absolute;
  left: 0px;
  font-size: 1.5em;
  bottom: 0px;
  width: 100%;
}
.slick-prev:before,
.slick-next:before {
  color: #000000;
  content: "" !important;
}
.slick-prev {
  color: #000000;
  left: -42px !important;
}

.slick-track {
  height:auto;
}
.next-slick-arrow,
.prev-slick-arrow {
color: #000000;
font-size: 2rem;
}


.thumbnail-slider-wrap {
  width: 15%;
  height: 0px;
  padding: 0 10px;

  i{
    padding: 40px 20px;
  }
}
.thumbnail-slider-wrap .slick-track .slick-slide {
  text-align: center;
  padding-bottom: 10px;
}
.thumbnail-slider-wrap .slick-track .slick-slide img {
  width: 100%;
}



@media only screen and (max-width: 980px) {
  
  .slider-wrapper {
    width: 95%;
    margin: auto;
    position: relative;
  }

  .thumbnail-slider-wrap {
    display: none;
  }

  .slick-slide-image {
    max-width: 100%; //65
    height: auto;
    border-radius: 8px;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    
  }

  .slick-prev {
    left: -28px !important;
  }

}

