.TWdesc {
    position: relative;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 2;
    font-size: 2rem;
    line-height: 1;
    font-weight: 400;
    color: black;
    background: rgba(255,255,255,0);
    float: left;
    
}


.TWdescLeft {
  float:right;
}

.LRLbutton{
  
    display: block;
    width: 100%;
    font-size: 1.8rem;
    line-height: 1;
    font-weight: 300;
    text-transform: uppercase;
    color: white;
    background: rgba(211, 141, 113, 0.61);
    padding: 2rem 0;
    margin: 3rem auto;
    border: 0;
    outline: none;
    cursor: pointer;
    text-align: center;
}

img {
  display: block;
  width: 100%;
  margin: 5rem 0;
}

.img {
    // margin: 2rem 0;
    width: 85%;
    padding-left: 15%;
}

.LRL1{
  background-color:rgba(77, 1, 255, 0.637); /* use rgba for fine adjustments */
  // background: url(${LRL_BG}) no-repeat right top
  background-blend-mode: screen ;
  // background-blend-mode: normal;
  background-repeat: no-repeat;
  background-position: right;
  .LRLbutton{
    background: rgba(214, 91, 153, 0.61);
  }
}

.LRL3{
  background-color:rgba(77, 1, 255, 0.637); /* use rgba for fine adjustments */
  // background: url(${LRL_BG}) no-repeat right top
  background-blend-mode: screen ;
  // background-blend-mode: normal;
  background-repeat: no-repeat;
  background-position: left;
  .LRLbutton{
    background: rgba(214, 91, 153, 0.61);
  }
  margin-bottom: 8rem;
}

// .LRL{
//   margin-bottom: 2rem;
// }


@media only screen and (max-width: 980px) {
  
    .TWdesc {
      width: 90%;
    margin: 0;
    padding-bottom: 1rem;
  
    
    }

    .TWdescLeft {
      float:left;
    }
  
    img {
        display: block;
        width: 100%;
        margin: 0;
      }

    .LRL3{
      margin-bottom: 6rem;
    }

  }

  