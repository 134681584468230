.quantityText{
  margin-top: 4rem;
}


.q-cartItem{

  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-content: center;

  .q-number{
    margin: 10px 20px;
  }
  .q-minus, .q-plus{
    cursor:pointer;
    width: 40px;
    height:40px;
    background:#f2f2f2;
    border-radius:4px;
    padding:8px 5px 8px 5px;
    border:1px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
  }
}