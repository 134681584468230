
.checkout {
  
  

    .cartDesc{
      max-width: 900px;
      margin: 2rem auto;
      text-align: right;


      .strike{
        text-decoration: line-through;
      }
      
      .radio{
        text-align: left;
        background-color: rgb(240, 240, 240);
        padding: 4rem 0 2rem 2rem;
        ul {
          li {
            font-size: 1.5rem;
            line-height: 3rem;
          }
          margin-bottom: 4rem;
          margin-top: 1rem;
        }

        .RadioLabelName{
            font-size: 1.8rem;
            font-weight: 400;
            line-height: 1;
        }

      }

      .shipOptionsForm {
        float: right;
      }
    
      .cartBtn {
        line-height: 1.5;
        margin-top: 3rem;
        margin-bottom: 3rem;
      }

      .stripeProtectContainer {
        width: 100%;
        
        img{
          margin: 4rem;
          max-width: 50rem;
          margin-left: auto;
          margin-right: auto;
          display: block;
        }
      }
  
      .stripeSecureNote{
        padding-top: 1rem;
      }

    }

    p{
      text-align: center;
    }


}


@media only screen and (max-width: 980px) {
  .checkout {
    .cartDesc{
      .cartBtn {
        line-height: 1.5;
        margin-top: 1rem;
        margin-bottom: 0;
      }
    }
  }
}

