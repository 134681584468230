

.detailsContainer{
  .detailsDiv{
    
    font-size: 1.25rem;
    font-weight: 400;
    color: "#333";
    text-align: justify;
    margin-block-start: 0;
    margin-block-end: 0;
    
    
  }
}