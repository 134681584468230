

$primary-color: #303030;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-tile-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  //set dimensions for invisible container
  .input-container {
    position: relative;
    // height:  7rem;
    width:  7rem;
    margin: 1.2rem;
    // margin-bottom: 0;

    //make actual radio input invisible
    // + stretch to fill container
    .radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      cursor: pointer;
    }

    //default tile styles
    .radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border: 2px solid $primary-color;
      border-radius: 5px;
      padding: 1rem;
      transition: transform 300ms ease;
    }
    
    .radio-tile-label {
      text-align: center;
      font-size: 1.1rem;
      font-weight: 600;
      // text-transform: uppercase;
      letter-spacing: 1px;
      // word-spacing: 3px;
      color: $primary-color;
    }

    //active tile styles
    .radio-button:checked + .radio-tile {
      background-color: $primary-color;
      border: 2px solid $primary-color;
      color: white;
      transform: scale(1.1, 1.1);
      
      
      .radio-tile-label {
        color: white;
        background-color: $primary-color;
      }
    }
  }

  .oneSize {
    width:  8rem;
  }

  .disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .one {
    display: block;
  }

}

.addToCart {
  // max-width: 20rem;
  padding: 3rem 0;
  .btn{
    font-size: 1.5rem;
  }
}


.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}




















// --------------------------------------------------------------------------------------------------------------

//OLD


.cartItem{
    
    display: flex;
    width: 300px;
    // margin: 0 auto;
    margin-top: 1rem;
    text-align: center;
  
  //   box-sizing: border-box;
    border-color: transparent;
  //   padding-top: 50px;
  
  
      #decrease {
          margin-right: -4px;
          border-radius: 8px 0 0 8px;
      }
    
      #increase {
          margin-left: -4px;
          border-radius: 0 8px 8px 0;
      }
  
  }
  
  .value-button {
      display: inline-block;
      border: 1px solid #ddd;
      margin: 0px;
      width: 40px;
      height: 40px;
      text-align: center;
      vertical-align: middle;
      padding: 11px 0;
      background: #eee;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    
    .value-button:hover {
      cursor: pointer;
    }
  
  
    .number {
      text-align: center;
      border: none;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      margin: 0px;
      width: 40px;
      height: 40px;
      padding: 11px 0;
    }
    
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }