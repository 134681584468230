.footer {

  background-color:rgba(183, 0, 255, 0.123); /* use rgba for fine adjustments */

  .footerDesc {
      padding-left: 12.3%;
      padding-top: 3rem;
      padding-bottom: 3rem;


      .footerTitle{
        margin:  3rem 0;
        font-weight: bold;
      }

      .footerInfo{
        padding-left: 8rem;
      }

      ul, ul li {
        padding: 0;
        margin: 0;
        // height: 100%;
      }

      ul {
        text-align: left;

        li {
          display: block;
          margin: 0;
          list-style-type: none;
          margin-bottom: 1.5rem;

          a {
            font-size: 1.4rem;
            line-height: 1.5rem;
            letter-spacing: 1px;
            color: black;
            text-decoration: none;
            cursor: pointer;

            .faicon {
              margin-right: 1rem;
            }

            .faiconruler {
              margin-right: 0.8rem;
            }

            .faiconSocial {
              margin-right: 3.5rem;
            }
          }
        }
      }
        
    }

    .footerCopy {
      padding: 2rem 5rem;
      margin: 0 auto;
      color: black;
      background-color:rgba(183, 0, 255, 0.123); /* use rgba for fine adjustments */
      font-size: 1.3rem;
      width: 100%;

      .footerCreatedBy{
          text-align: right;

          a {
            padding-left: 1rem;
          }
      }
    }
}



@media only screen and (max-width: 980px) {

  .footer {

    .footerDesc {
      padding-left: 1rem;
      padding-right: 1rem;

      .footerInfo{
        padding-left: 0;
      }
  }

    .footerCopy {
      padding: 2rem 1rem; 
      .footerCreatedBy{
        padding: 2rem 0 3rem;
        display: block;
        text-align: center;
      }
    }
  }
}