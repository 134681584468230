.products {
    display: block;
    width: 100%;
    padding: 0;
    margin: 2rem 0;

    .subCategorySelect {
      display: none;
    }
  
    .productResults {
      display: flex;
      flex-wrap: wrap;
      margin: 3rem -10px 0;
    }

    .moreStock {
      display: inline-block;
      width: 100%;
      background-color: black;
      height: auto;
      margin: 0 auto;
      padding: 0 10px;
    
      ul, li {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-left: 2%;
        text-align: center;
        justify-content: center;
      }
    
      ul {
        float: none;
    
        li {
          display: inline-block;
    
            p {
              display: block;
              font-size: 1.9rem;
              line-height: 1;
              color: white;
              padding: 0;
              margin: 1rem auto ;
              letter-spacing: 1rem;
            }
        }
      }
    
    }


  }
  
  .product {
    // width: 33.333333333333333%;
    width: 25%;
    margin: 0 auto 2rem 0;
    padding: 0 10px;
    
  
    .thumb {
      display: block;
      width: 100%;
      background: rgb(37, 35, 35);
  
      img {
        display: block;
        width: 100%;
        margin: 0;
      }
    }

    .imageOverlay{
      position: relative;
      text-align: center;
      color: white;

      img{
        opacity: 0.3;
        // content: "Reference ";
      }
      .soldOutOverlay{
        position: absolute; /* Position the background text */
        bottom: 0; /* At the bottom. Use top:0 to append it to the top */
        background: rgb(0, 0, 0); /* Fallback color */
        background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
        color: #f1f1f1; /* Grey text */
        width: 100%; /* Full width */
        padding: 20px; /* Some padding */
      }


    }



    .discDesc {
      color: red;
      padding: 0;
      margin: 0;
    }
  
    .details {
      display: block;
      width: 100%;
      padding: 1rem 0 0;
      margin: 0 auto;
  
      ul, ul li {
        padding: 0;
        margin: 0;
      }
  
      ul {

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-self: flex-end;

        li {
          width: 100%;
          list-style-type: none;
          text-align: center;
          margin: 0 0 .5rem;

          a {
            color: rgb(41, 41, 41);
          }
  
          .name {
            display: flex;
            font-size: 1.5rem; // 1.8rem
            line-height: 1.6rem;
            min-height: 3rem;
            font-weight: 400;
            justify-content: center;
          }
  
          .price {
            font-size: 1.5rem;
            line-height: 1;
            font-weight: 400;
            color: red;
            

            .orgPrice{
              color: rgb(100, 100, 100);
              font-size: 1.5rem;
              letter-spacing: .2rem;
              text-decoration: line-through;
              padding-right: 1rem;
              
            }
          }
          .noOffer {
            color: rgb(100, 100, 100);
          }
  
          .addToCart {
            margin: 0;
            padding-top: 1rem;
            flex-direction: column-reverse;

          }
        }
      }
  
    }
  }

  
  @media only screen and (max-width: 980px) {

    .products{

      
      .product {
        width: 50%;
        
        .details ul li {

          margin-bottom: 0.2rem;

          .name {
            min-height: 5rem;
            font-size: 1.4rem;
          }
          
        }
        .addToCart {
          margin: 0;
          padding: 0;
          padding-bottom: 3rem;
        }

        .imageOverlay{
          .soldOutOverlay{
            padding: 10px; /* Some padding */
            
          }
        }

      }

      .moreStock {
        
        ul {
          li {
              p {
                font-size: 1.5rem;
                letter-spacing: .5rem;
              }
          }
        }
      
      }
    }
  }

  @media only screen and (max-width: 1200px) and (min-width: 981px)  {
    .products{

      
      .product {
        width: 33.33333333%;

        .details ul li {

          .name {
            min-height: 3rem;
          }
        }
      }
    }
  }