.hide{
    visibility: hidden;
}

.modalBackdrop{
    // position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.StickyContainer {
    
    .StickyItem{
        
        display: flex;
        position: fixed;
        align-items: center;
        right: 5rem;
        bottom: 5rem;
        z-index: 99;
        background: rgb(255,255,255);
        background: radial-gradient(circle, rgba(236,236,236,1) 0%, rgba(198,198,198,1) 100%, rgba(198,198,198,1) 100%);
        border-radius: 1rem;
        // padding-left: 2rem;
        height: 6rem;
       
        .stickyButton{
            padding: 4rem;
            cursor: pointer;
        }

        .closeButton{
            width: 2rem;
            cursor: pointer;
            margin-right: 2rem;
        }


        .ModalContainer{
            display: none;
            // display: block;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            // width: 450;
            width: MIN(80vw, 50rem);
            // width: clamp(0rem, 80vw, 50rem);
            background-color: #000000;
            background: radial-gradient(circle, rgba(236,236,236,1) 0%, rgba(198,198,198,1) 100%, rgba(198,198,198,1) 100%);
            box-shadow: 24;
            // p: 4;
            border-radius: 2.5rem;
            // maxWidth: { xs: 250, sm: 300, md: 450 };
            // padding: 5rem;
            padding: MIN(7vw, 5rem);

            .closeModal{
                position: absolute;
                top: 2rem;
                right: 0;
                img{
                    margin: 0;
                }
            }
        }

        .modalOpen{
            display: block;
        }

        .modalClose{
            display: none;
        }
        
        p{
            margin: 2rem;
            color: #D02586;
            font-size: 3.4rem;
            font-weight: bold;
            letter-spacing: 1rem;
            font-family: 'DM Sans', Arial;
        }

        i{
            cursor: pointer;
            margin: 1rem;
            padding: 1rem;
            color: #D02586;
        }

       
    }

}

@media only screen and (max-width: 980px) {
    .StickyContainer {
    
        .StickyItem{
            padding-left: 0;
            right: 2rem;
            bottom: 5rem;

            p{
                margin: 2rem;
                color: #D02586;
                font-size: 2.1rem;
                font-weight: bold;
                letter-spacing: 0.6rem;
                font-family: 'DM Sans', Arial;
            }
        }
    }
}