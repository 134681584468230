.directory {
    height: 50%;
    width: 100%;
    margin-bottom: 10rem;
  
    .wrap {
      display: inline-block;
      width: 100%;
      height: 100%;

      img {
        margin: 0;
      }
  
    }
  
  }
  
  @media only screen and (max-width: 980px) {
  
    .directory .wrap .item {
      width: 100%;
      height: 100%;
  
      a {
        font-size: 1.5rem;
      }
    }
  
  }