.SuccessContainer{
    display: "flex";
    justify-content: "center";
    align-items: "center";
    width: 100%;



    .blank{
        position: relative;
        height: 450px;
    }

    .paying{
        
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.527);
        height: 100vh;
        width: 100vw;
        z-index: 2;
        backdrop-filter: blur(1px);


    .spinner{
        z-index: 1;
      position: absolute;
      top: calc(50%);
      left: calc(50%);
  
        color: #000000;
        font-size: 20px;
        width: 1em;
        height: 1em;
  
        border-radius: 50%;
        text-indent: -9999em;
        -webkit-animation: load4 1.3s infinite linear;
        animation: load4 1.3s infinite linear;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
  
      @-webkit-keyframes load4 {
        0%,
        100% {
          box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
        }
        12.5% {
          box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
        }
        25% {
          box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
        }
        37.5% {
          box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
        }
        50% {
          box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
        }
        62.5% {
          box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
        }
        75% {
          box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
        }
        87.5% {
          box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
        }
      }
      @keyframes load4 {
        0%,
        100% {
          box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
        }
        12.5% {
          box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
        }
        25% {
          box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
        }
        37.5% {
          box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
        }
        50% {
          box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
        }
        62.5% {
          box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
        }
        75% {
          box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
        }
        87.5% {
          box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
        }
      }
      
  
    }
    }




    

    .OrderConfirmed{
        text-align: center;

        i{
            padding-top: 6rem;
            padding-bottom: 3rem;
        }

        .confirmedAnimation{

            $green: #6243d3;
            $blue: #17d3ff;
            $pink: #ff4e91;
            
            $duration: 1000;
            
            @function randomNum($min, $max) {
              $rand: random();
              $randomNum: $min + floor($rand * (($max - $min) + 1));
            
              @return $randomNum;
            }
            

            
            .confetti {
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              width: 100%;
              height: 60vh;
              overflow: hidden;
            }
            
            .confetti-piece {
              position: absolute;
              width: 8px;
              height: 16px;
              background: $green;
              top: 0;
              opacity: 0;
              
              @for $i from 1 through 13 {
                &:nth-child(#{$i}) {
                  left: $i * 7%;
                  transform: rotate(#{randomNum(-80, 80)}deg);
                  animation: makeItRain $duration * 1ms infinite ease-out;
                  animation-delay: #{randomNum(0, $duration * .5)}ms;
                  animation-duration: #{randomNum($duration * .7, $duration * 4.2)}ms
                }
              }
              
              &:nth-child(odd) {
                background: $blue;
              }
              
              &:nth-child(even) {
                z-index: 1;
              }
              
              &:nth-child(4n) {
                width: 5px;
                height: 12px;
                animation-duration: $duration * 2ms;
              }
              
              &:nth-child(3n) {
                width: 3px;
                height: 10px;
                animation-duration: $duration * 2.5ms;
                animation-delay: $duration * 1ms;
              }
              
              &:nth-child(4n-7) {
                background: $pink;
              }
            }
            
            @keyframes makeItRain {
              from {
                opacity: 0;
              }
              
              50% {
                opacity: 1;
              }
              
              to {
                transform: translateY(400px);
              }
            }

            @keyframes makeItRain2 {
              from {
                opacity: 0;
              }
              
              50% {
                opacity: 1;
              }
              
              to {
                transform: translateY(400px);
              }
            }


        }




}
}