.OrderDetailsTC{
    width: 80%;

        .OrderDetailsTCell{
            font-size: 16px;
            width: 10%;
        }
        
        .OrderDetailsTCellItems{
            max-height: 2rem;
            padding:  1rem 0;

            img{
                margin: 0;
            }
        }

}


@media only screen and (max-width: 980px) {

    .OrderDetailsTC{
        width: 100%;

        .OrderDetailsTCell{
            width: 0;
        }

    }
}