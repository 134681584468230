
.productCard {
    margin: 0 10rem 10rem;
  
    
    .hero {
      display: block;
      width: 100%;
      margin: 2rem auto;


      position: -webkit-sticky;
      position: sticky;
      align-self: flex-start;
      top: 1rem;
  
      img {
        display: block;
        width: 100%;
        margin: 0;
      }
    }
  
    .productDetails {
      ul, ul li {
        padding: 1rem 0;
        margin: 2rem 0;
      }
  
        ul li {
          list-style-type: none;
          margin: 0 auto 1rem;
    
          h1 {
            margin: 0;
            font-size: 2.2rem;
            text-align: left;
            letter-spacing: normal;
            text-transform: none;
          }

         

          .discDesc{
            font-size: 15px;
            color: red;
            letter-spacing: .2rem;
          }

         
          .OrgPrice{
            text-decoration: line-through;
          }
        }

        .price {
          font-size: 18px;
          line-height: 1;
          font-weight: 400;
          color: red;
          letter-spacing: .2rem;

          .orgPrice{
            color: black;
            font-size: 18px;
            letter-spacing: .2rem;
            text-decoration: line-through;
            padding-right: 1rem;
          }
        }
      .noOffer {
        color: black;
      }


       .sizes {
         
          margin-top: 4rem;

         span{
            width:50%;
            display:inline-block;
         }
        .sizeChart{
           text-align: right;
           text-decoration-line: underline;
         }
       }

    }
  
  }


  .cartItem {
    td img {
      display: block;
      width: 100%;
    }
  }

  .cartBtns {
    display: block;
    width: 100%;
  }

  .cartBtn {
    cursor: pointer;
  }



  .Slides {
      margin: 0;
      border-top: 4px solid lightgray;
    }

  @media only screen and (max-width: 980px) {
  
    .productCard {
      margin: 0 1rem 10rem;
    }
    
  }






  
