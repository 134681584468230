.btn {
    display: block;
    width: 100%;
    font-size: 1.8rem;
    line-height: 1;
    font-weight: 300;
    text-transform: uppercase;
    color: white;
    background: black;
    padding: 1rem 10px;
    margin: 0 auto;
    border: 0;
    outline: none;
    cursor: pointer;
  }