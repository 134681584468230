.infoContainer{

    .whovr{
        margin-bottom: 5rem;

        h5 {
            i{
                line-height: 2.5rem;
            }
        }
    }
    
    .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 2rem;
    }

    .customers{
        margin-top: 10rem;
        background-color: rgba(0, 0, 0, 0.877);
        line-height: 5rem;
        color: white;
    }

    .promiseImg{
        display: block;
        max-Width: 100%;
        max-Height: 100%;
        padding: 0;
    }

    img {
        margin: 0;
    }

    .customerImg {
        max-Width: 200px;
    }

    .storyDescContainer {
        margin-top: 8rem;
    }

    .promiseDescGrid, .storyDescGrid{
        margin: auto;
    }
}



@media only screen and (max-width: 980px) {
    .infoContainer{
        img {
            margin: auto;
            max-Width: 50%;
        }

        .customerGrid{
            padding-bottom: 0;
            .customerImg{
                max-Width: 100%;
            }
        }

        .whovr{
            margin-bottom: 10rem;
        }
    }
}