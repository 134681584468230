
.header {
    height: 6.5rem;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
  
    .wrap {
      position: relative;
      height: 100%;
      max-width: 1450px;
      margin: 0 auto;
  
      .logo {
        position: absolute;
        top: 50%;
        width: 21%;
        left: 39.5%; // calc( (100 - __30__) /2)
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        transform: translateY(-50%);
  
        img {
          display: block;
          width: 100%;
          margin: 0;
        }
      }

      .menuToHide {
        display: none;
      }
  
      nav {
        display: block;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0 ;
  
        ul, ul li {
          padding: 0;
          margin: 0;
          height: 100%;
        }
  
        ul {
          text-align: center;
  
          li {
            display: inline-block;
            margin: 0 4rem;
            list-style-type: none;
  
            a {
              font-size: 1.2rem;
              line-height: 6.5rem;
              vertical-align: middle;
              color: black;
              text-decoration: none;
              text-transform: uppercase;
              cursor: pointer;
              letter-spacing: 2px;
            }
            span {
              font-size: 1.2rem;
              line-height: 6.5rem;
              vertical-align: middle;
              color: black;
              text-decoration: none;
              text-transform: uppercase;
              cursor: pointer;
              letter-spacing: 2px;
            }
          }
        }
      }
  
      .callToActions {
        position: absolute;
        top: 50%; right: 10px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        transform: translateY(-50%);
  
        i {
          display: none;
        }
  
        ul, li {
          margin: 0;
          padding: 0;
        }
  
        li {
          display: inline-block;
          list-style-type: none;
          margin-right: 1.5rem;
  
          &:last-child {
            margin-right: 0;
          }
  
          a, span {
            font-size: 1.2rem;
            line-height: 6.5rem;
            color: black;
            text-decoration: none;
            text-transform: uppercase;
            cursor: pointer;
          }

          p {
            font-size: 1.2rem;
            color: black;
          }

  
        }
  
      }
  
    }
  
  }
  

  .header1 {
    height: 10rem;
  }
  
  .mobileMenu {
    display: none;
  }
  
  @media only screen and (max-width: 980px) {
  
    .header2 {
      display: none;
    }

    .menuToHide {
      display: block;
    }

    .mobileMenu {
      display: block;
    }
  
    

    .header .wrap {
      .callToActions {
        i {
          display: block;
        }
  
        ul li {
  
          &.hideOnMobile {
            display: none;
          }
  
          a , span {
            // font-size: 0;
            // text-align: left;
            
              font-size: 1.8rem;
              line-height: 6.5rem;
              vertical-align: middle;
              color: rgb(0, 0, 0);
              text-decoration: none;
              text-transform: uppercase;
              cursor: pointer;
              text-align: left;
              width: 100%;
              // padding-left: 2rem;
  
            i {
              font-size: 2.8rem;
              padding-left: 0;
              padding-right: 1rem;
            }
          }
          
        }
        

        p {
          display: none;
        }
      }
  
      .mainMenu {
        display: none;
        position: absolute;
        height: auto;
        top: 100%;
        left: 0;
        z-index: 10;
        background-color: white;
        border-bottom: 1px solid lightgray;
  
        &.active {
          display: block;
        }
  
        ul li {
          width: 100%;
          margin: 0;
          border-top: 1px solid lightgray;
  
          a {
            display: block;
            width: 100%;
            text-align: left;
            padding: 0 10px;


            i{
              padding-right: 2rem;
              // padding-bottom: 10rem;
            }
          }
        }
      }
  
    }

    .header .wrap .logo {
      margin: 0 auto;
      // width: 20.0rem;
      width: 55%;
      position: absolute;
      // top: 50%;
      // left: 0; // calc( (100 - __30__) /2)
      left: 10px;
      // top: 5rem;
      -webkit-transform: translateY(-55%);
      -moz-transform: translateY(-55%);
      transform: translateY(-55%);

      img {
        display: block;
        width: 100%;
        margin: 0;
        padding: 0;
      }
    }
  
  }